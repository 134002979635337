import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { lg, md, xl } from 'config/variables'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { JoybirdProps } from 'components/Props/joybird'
import Casebanner from 'components/Casebanner/Casebanner'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Automatingsec from 'components/Automatingsec/Automatingsec'
import Resultwithimagessec from 'components/Resultwithimagessec/Resultwithimagessec'
import Casecontentfullwimg from 'components/Casecontentfullwimg/Casecontentfullwimg'
import Rigidversusflexiblesec from 'components/Rigidversusflexiblesec/Rigidversusflexiblesec'
import Reliabilityinstancessec from 'components/Reliabilityinstancessec/Reliabilityinstancessec'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'

export const JoybirdPage = styled.div`
  .casebanner {
    .container {
      .img-block {
        .banner-img {
          .banner-bg {
            background-color: #0db9cb;
          }
        }
      }
    }
  }
  .automatingSec  {
    padding:0px;
    margin-bottom:146px;
    ${xl(`
        margin-bottom:100px;
    `)} 
    ${lg(`
        margin-bottom:60px;
    `)} 
  ${md(`
        margin-bottom: 40px;
    `)} 
    &::after {
      display:none;
    }
  }
  .joybirdDesigningSec {
    .Casecontentfullwimg  {
      padding-bottom: 94px;
      position: relative;
        ${xl(`
            padding-bottom: 80px; 
        `)} 
        ${lg(`
            padding-bottom: 60px; 
        `)} 
      ${md(` 
            padding-bottom: 40px;
        `)} 
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 729px;
          bottom: 0;
          left: 0;
          background-image: linear-gradient(
            to bottom,
            rgba(252, 253, 255, 0),
            #f7f7f7
          );
          z-index: -1;
        }
    }
  }
`

const Joybird = memo(props => {
  const { data } = props
  const { location } = props
  let RightScreenList = [
    props.data.joybirdHomeScreen.childImageSharp.fluid,
    props.data.joybirdWillitfitScreen.childImageSharp.fluid,
  ]
  let leftScreenList = [props.data.leftScreenListImg.childImageSharp.fluid]
  let topImages = [props.data.resultTopImages.childImageSharp.fluid]

  const joybirdDesigning = props.data.joybirdDesigning.childImageSharp.fluid
  const joybirdDeployDaigram =
    props.data.joybirdDeployDaigram.childImageSharp.fluid
  const joybirdProjectDaigram =
    props.data.joybirdProjectDaigram.childImageSharp.fluid

  let productImg = [
    props.data.mixpanel.childImageSharp.fluid,
    props.data.mixpanel2.childImageSharp.fluid,
    props.data.mixpanel3.childImageSharp.fluid,
  ]

  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })
  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="Joybird"
        description=""
        keywords={[`simform`]}
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <JoybirdPage>
        <Casebanner
          bannerdata={JoybirdProps}
          {...props}
          showFullimages={true}
        />
        <Caseintroduction
          caseintrodata={JoybirdProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Automatingsec
            Automatingsecdata={JoybirdProps}
            {...props}
            showFullimages={false}
          />
        </div>
        {showContent && (
          <>
            <Resultwithimagessec
              resultwithimagedata={JoybirdProps}
              {...props}
              leftScreenList={leftScreenList}
              rightScreenList={RightScreenList}
              topImages={topImages}
            />
            <div className="joybirdDesigningSec">
              <Casecontentfullwimg
                casecontentfullwdata={JoybirdProps.casecontentfullwProps}
                fluid={joybirdDesigning}
              />
            </div>
            <Rigidversusflexiblesec
              Rigidflexibledata={JoybirdProps}
              {...props}
            />
            <Casecontentfullwimg
              casecontentfullwdata={JoybirdProps.isolationContentProps}
              fluid={joybirdDeployDaigram}
            />
            <Casecontentfullwimg
              casecontentfullwdata={JoybirdProps.performanceContentProps}
              fluid={joybirdProjectDaigram}
            />
            <Reliabilityinstancessec
              ReliabilityinstancesData={JoybirdProps.ReliabilityinstancesProps}
              {...props}
              fluid={productImg}
            />
            <Relatedcaselist relatedcaselistdata={JoybirdProps} {...props} />
            <Casecontactcardinfo Casecontactcardinfodata={JoybirdProps} />
          </>
        )}
      </JoybirdPage>
    </Layout>
  )
})
export default Joybird

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/joybird-banner@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    deploymentAutomationImage: file(
      relativePath: { regex: "/deploymentautomationImage@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    joybirdHomeScreen: file(
      relativePath: { regex: "/joybird-home-screenss@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 540) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    joybirdWillitfitScreen: file(
      relativePath: { regex: "/joybird-willitfit-screenss@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 540) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    leftScreenListImg: file(
      relativePath: { regex: "/joybird-story-screenss@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 540) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    resultTopImages: file(
      relativePath: { regex: "/joybird-responsive-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    joybirdDesigning: file(
      relativePath: { regex: "/joybird-designing-comp-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mobileAppsImages: file(
      relativePath: { regex: "/rigid-versus-flexible@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1146) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    performanceThumb: file(
      relativePath: { regex: "/react-front-end-architec@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 601) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    joybirdDeployDaigram: file(
      relativePath: { regex: "/joynird-deploy-daigram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    joybirdProjectDaigram: file(
      relativePath: { regex: "/joybird-project-daigram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mixpanel: file(relativePath: { regex: "/combining-resource@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 552) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mixpanel2: file(relativePath: { regex: "/img-ix-size@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 552) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mixpanel3: file(relativePath: { regex: "/filters-e-commerce@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 552) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/redbull-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/fitcom-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
