import React from 'react'

export const JoybirdProps = {
  bannerProps: {
    heading: 'Joybird',
    bannerPara:
      'Bringing mid-century furniture to digitally personalized shopping experience',
    BannerAlt: 'Joybird Case Study',
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        E-commerce furniture sales have emerged as a major growth area, rising
        18% in 2015, second only to grocery, according to research from
        Barclays. Some 15% of $70 billion in U.S. furniture sales are now
        online. <br /> <br /> Joybird is a modern online furniture store. Their
        custom-made and curated furniture is built to meet the demands and
        unique tastes of customers. Joybird was acquired by La-Z-Boy to
        exponentially grow their reach from revenue of $55 million to beyond.
      </>
    ),
  },
  AutomatingsecProps: {
    Title: 'Component Driven Progressive Web Apps For Faster Development Time',
    para: (
      <>
        Joybird was looking for a development partner to take their existing
        e-commerce platform to the next level with modular user experience.
        Every milli-seconds count in e-commerce. Joybird’s tech team was
        following the simple principle of Better speed and Better UX/SEO = more
        $$$. They wanted to harness the power of Progressive Web Apps (PWA) so
        that they could delight customers, grow engagement, and increase
        conversions.
        <br />
        <br /> For example, Alibaba.com built a PWA that led to a fast,
        effective, and reliable mobile web experience. The new strategy
        delivered a 76% increase in total conversions across browsers and 4
        times higher interaction rate from Add to Homescreen.
      </>
    ),
  },
  resultwithimageProps: {
    heading: 'Result',
    para: (
      <>
        Our main goal was to design an enterprise library of thousands of React
        Components. React allows for modular development which enables easy code
        reuse across platforms (Web and Mobile both). With customization being
        the salient feature of Joybird component driven UI would help make
        changes faster.
        <br />
        <br /> As component libraries grew in breadth and depth, each team
        required less custom work because they had a stronger and richer
        foundation to build upon. Reusable components are self-contained. They
        encapsulate a set of concerns in an approachable and reusable way.
        Developers don’t have to be experts to use a component because it
        provides a clear public interface. All of these benefits led to faster
        development.
      </>
    ),
  },
  casecontentfullwProps: {
    heading: 'Designing component driven architecture for reusability',
    para: (
      <>
        Building reusable components required thoughtful consideration with some
        additional refactoring effort. The React team at Facebook encourages the
        use of a component composition for building container components. It
        makes more sense when you want to give complete flexibility to build.
        E-commerce app by using combinations of different React components over
        and over again.
      </>
    ),
    bottomPara: (
      <>
        {' '}
        Start small! That is our mantra. It is simplest to serve a single
        product. Our goal is merely to design components that can be used in
        multiple places within a single app.
        <br />
        <br /> Consistency was one of the most important factors for us.
        Promoting a consistent look, feel, accessibility, internationalization
        and features would help Joybird brand standout. Enforced consistency
        increases efficiency by avoiding decision fatigue. Reusable components
        can also increase efficiency by saving bandwidth. You send fewer bytes
        down the wire when you can declare a single component that is used in
        multiple places on a given page. <br /> <br /> The best part of reusable
        components is that you can use them in your mobile app as well, so there
        is no need to write duplicate code for React native app. This was
        helpful as we were also supposed to build stripped down version of web
        app for iPads which is to be used by in-store Joybird employees to show
        various products and options to walk in customers.
      </>
    ),
  },

  RigidflexibleProps: {
    Title: 'Rigid Versus Flexible',
    para: (
      <>
        Developers want to make many items flexible or rigid based on context.
        For example, users will want to change the style, such as colors,
        padding, size, font and so on. Finally, perhaps some of our components
        will contain built-in logic but also accept functions as a parameter to
        override default behavior.
      </>
    ),
    imgCaption: 'Front end architecture (React+Redux)',

    performancPara: (
      <>
        {' '}
        We utilized React with Redux to separate the visual components from
        application state, actions, and reducers. This meant we could abstract
        the React components into one GitHub organization and Redux actions,
        reducers, and connected components into another. By publishing all of
        these in a private npm registry, we make it easy for developers to
        install, try out, and upgrade not only the shared UI elements, but also
        the actions and reducers that implement our business logic and API
        calls.
      </>
    ),
    RAMConsumptionTitle:
      'Components are then published in our private npm registry, meaning that developers can easily install a specific component version, ensuring that their apps won’t suddenly break on upgrade.',
    RAMRightTitle: 'Easy code sharing across teams',
    RAMConsumptionPara: (
      <>
        At this point, since the code is being shared across teams, we needed to
        ensure consistent structure and coding standards across hundreds of
        components, even as dependencies are upgraded and needs change. <br />{' '}
        <br /> Starting from a common structure and establishing consistent
        coding standards across all projects enables us to maintain modern best
        practices throughout the organization and increases the confidence
        developers have in each other’s code, improving the chances that
        reusable components will actually be reused. <br /> <br /> We made sure
        that components were versioned, easy to install, and upgradeable. We put
        all of our React components into a separate GitHub organization. We used
        functional repos, such as a “Navigation” repo containing breadcrumb,
        tabs, and sidenav link components.
      </>
    ),
  },

  isolationContentProps: {
    heading: 'Setting up CI/CD to test components in isolation',
    para: (
      <>
        Given the nature of the store, there were frequent updates in terms of
        new React components which called for automated testing and deployment
        approach to reduce the time to make them live so that it can be used by
        the content manager. Components were tested in isolation to assure
        liability so when they’re composed together, we only need to worry about
        integration concerns between components.
      </>
    ),
    imgCaption: 'Joybird Deployment Infrastructure',
    bottomPara: (
      <>
        {' '}
        We used TeamCity as our CI / CD (continuous integration / continuous
        deployment) server to meet the constant demands of small and major
        updates to the web app. As changes to develop branches occur on
        BitBucket, TeamCity automatically takes notice, pulls the related code,
        builds the related services, and deploys changes to server if CI/CD
        pipeline is executed successfully. The tests are run to test across
        multiple devices, browsers, and screen resolutions.
      </>
    ),
  },
  performanceContentProps: {
    heading: 'Faster performance for better user experience',
    para: (
      <>
        E-commerce applications use tons of functionalities, such as; ability to
        view product pictures with zoom in capabilities, filters and sorting
        options, credit card components, login forms, onboarding, carousels,
        navigation, and more. It was important that we utilize the best
        technologies which serves different purposes, ultimately leads to the
        best performing system. <br /> <br /> Rather than relying on their main
        database - MongoDB for all the data processing, we utilised Redis
        database as a wrapper which can quickly give data like product
        availability, products, fabrics, wood stands codes to process the
        relevant images.
      </>
    ),
    imgCaption: 'Joybird Project Infrastructure',
  },
  ReliabilityinstancesProps: {
    ReliabilityinstancesDetails: [
      {
        trackbottomPara:
          'E-commerce web and mobile applications are often data-driven and require large sets of data combining related resource. Unlike any other API design architectures, GraphQL facilitates requesting data for different entities in single call and sub set of the data as well if need be. We designed flexible data fetching layer using GraphQL so that it can deliver the data based requests coming from mobile client and web client.',
      },
      {
        trackbottomPara:
          'Attractive images plays a vital role in any E- commerce solution.There will be millions of images on any E - commerce platform making it difficult to optimize for different resolutions of devices like mobile, tablet, desktop etc.We used Imgix, end to end image optimization solution, to transform, optimize and cache entire images library which can meet the demand of devices of various sizes and resolutions. ',
      },
      {
        trackbottomPara:
          'Search, filters and sort will be the most used features for any E-commerce website. We utilized fast and efficient full text search service by Elastcsearch to provide flawless experience to users.',
      },
    ],
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'Redbull Photobooth',
        para: 'Shortcut to get featured on Redbull website',
        className: 'powder-blue',
        link: 'case-studies/redbull-photobooth/',
      },
      {
        title: 'Fitcom',
        para: 'Custom app solutions for health, wellness & fitness industries',
        className: 'bluegrey',
        link: 'case-studies/fitcom/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
