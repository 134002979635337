import styled from 'styled-components'
import { primaryColor, metaFont, md, lg, xl } from 'config/variables'
export const RigidversusflexiblesecStyles = styled.div`
  margin-bottom: 126px;
  padding-bottom: 100px;
  position: relative;
    ${xl(`
        padding-bottom: 80px; 
        margin-bottom:100px;
    `)} 
    ${lg(`
        padding-bottom: 60px; 
        margin-bottom:60px;
    `)} 
  ${md(` 
        padding-bottom: 40px;
        margin-bottom:40px;
    `)} 
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 729px;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(252, 253, 255, 0),
        #f7f7f7
      );
      z-index: -1;
    }
  
  .mobileAppsheading {
     margin-bottom:74px;
     ${xl(`
      margin-bottom:60px;
    `)}
     ${lg(`
        margin-bottom:40px;
    `)}
     ${md(` 
        margin-bottom:20px;
    `)}
  }
  .mobileAppsImages {
    padding-bottom: 80px;
     ${xl(`
      padding-bottom: 60px;
    `)}
     ${lg(`
        padding-bottom: 40px;
    `)}
     ${md(` 
        padding-bottom: 20px;
    `)}
  }
  .performanceContentRow {
    display: flex;
    justify-content: space-between;
    padding-bottom: 100px;
    align-items:center;
    ${xl(`
        align-items:center;
        padding-bottom: 80px;
    `)}
     ${lg(`
        flex-direction: column;
        padding-bottom: 60px;
    `)}
     ${md(` 
        padding-bottom: 40px;
    `)}
    .performancDetails {
      flex:1 1 auto;
      padding-right: 60px;
      ${lg(`
         flex: 1 1 auto;
         padding-right: 0;
         padding-bottom:25px;
      `)}
      h3 {
        margin: 0px 0 10px;
      }
    }
    .performancThumb {
      flex: 0 0 504px;
      padding-left: 17px;
      ${lg(`
          flex: 1 1 auto;
      `)}
      figure {
        margin: 0px;
      }
    }
  }
  .RAMConsumptionContentRow {
    display: flex;
    justify-content: space-between;
    ${md(`
          flex-direction: column;
      `)}
    .RAMConsumptionTitle {
      flex: 0 0 350px;
      ${lg(`
          flex: 0 0 230px;
      `)}
      ${md(`
          flex: 1 1 auto;
          padding-bottom:5px;
      `)}
      h3 {
        font-family: ${metaFont};
        font-weight: bold;
        line-height: 1.67;
      }
    }
    .RAMConsumptionDetails {
      flex: 1 1 auto;
      padding-left: 62px;
      ${lg(`
             padding-left: 21px;
      `)}
       ${md(`
       padding-left: 0px;
      `)}
      h2 {
        margin-bottom:30px;
        ${lg(`
             margin-bottom:20px;
      `)}
      }
    }
  }
`
