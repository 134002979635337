import React from 'react'
import Image from 'components/ImgOptimized'
import { RigidversusflexiblesecStyles } from './Rigidversusflexiblesec.styles'

function Rigidversusflexiblesec(props) {
  const { RigidflexibleProps } = props.Rigidflexibledata
  const {
    data: {
      mobileAppsImages: {
        childImageSharp: { fluid: mobileAppsImages },
      },
      performanceThumb: {
        childImageSharp: { fluid: performanceThumb },
      },
    },
  } = props
  return (
    <RigidversusflexiblesecStyles>
      <div className="container">
        <div className="mobileAppsheading">
          <h2>{RigidflexibleProps.Title}</h2>
          <p>{RigidflexibleProps.para}</p>
        </div>
        <div className="mobileAppsImages">
          <Image
            fluid={mobileAppsImages}
            lazyload={{
              enabled: true,
              once: true,
              offset: 1000,
              heigth: 680,
            }}
          />
        </div>
        <div className="performanceContentRow">
          <div className="performancDetails">
            <h3>{RigidflexibleProps.performancTitle}</h3>
            <p>{RigidflexibleProps.performancPara}</p>
          </div>
          <div className="performancThumb">
            <figure>
              <Image
                fluid={performanceThumb}
                lazyload={{
                  enabled: true,
                  once: true,
                  offset: 1000,
                  heigth: 408,
                }}
              />
              <div className="img-caption">{RigidflexibleProps.imgCaption}</div>
            </figure>
          </div>
        </div>
        <div className="RAMConsumptionContentRow">
          <div className="RAMConsumptionTitle">
            <h3>{RigidflexibleProps.RAMConsumptionTitle}</h3>
          </div>
          <div className="RAMConsumptionDetails">
            <h2>{RigidflexibleProps.RAMRightTitle}</h2>
            <p>{RigidflexibleProps.RAMConsumptionPara}</p>
          </div>
        </div>
      </div>
    </RigidversusflexiblesecStyles>
  )
}

export default Rigidversusflexiblesec
